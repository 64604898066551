import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import AlertTooltip from '@shared/ui/tooltips/AlertTooltip';
import withPercentageIncomeBadge from '@shared/ui/badges/withPercentageIncomeBadge';
import { BodyMedium, BodySmall } from '@components/styled/Typography';
import withMddBadge from '@shared/ui/badges/MddBadge/withMddBadge';
import { ContainerColumn, ContainerRow } from '@components/styled';
import { WithSkeleton } from '@shared/ui/progress-loaders/Skeleton';
import PortfolioFollowersPreview from '../../../components/FollowersPreview';
import MonthlyReturnPositions from '../../../components/MonthlyReturnPositions';
const IncomeBadge = withPercentageIncomeBadge(BodyMedium);
const MddBadge = withMddBadge(BodyMedium);
const BadgeColumn = styled(ContainerColumn)(props => ({
    width: 'auto',
    height: 'auto',
    gap: props.theme.spacing_sizes.s,
}));
const BadgeLabel = styled(BodySmall)(() => ({
    lineHeight: '15px',
}));
const MonthlyReturnRow = styled(ContainerRow)(props => ({
    width: 'auto',
    height: 'auto',
    gap: props.theme.spacing_sizes.m,
}));
const ListContent = ({ portfolio = undefined, isLoading = false, }) => {
    var _a, _b, _c, _d;
    const { t } = useTranslation();
    const userSubscribed = useMemo(() => !!(portfolio === null || portfolio === void 0 ? void 0 : portfolio.user_subscription_summary), [portfolio]);
    return (_jsxs(_Fragment, { children: [_jsxs(BadgeColumn, { children: [_jsx(WithSkeleton, { width: 69, height: 15, isLoading: isLoading, children: _jsx(BadgeLabel, { children: t('portfolio.past_month') }) }), _jsx(WithSkeleton, { width: 112, height: 32, isLoading: isLoading, children: _jsx(IncomeBadge, { badgeVariant: 'tinted', percentageIncome: (portfolio === null || portfolio === void 0 ? void 0 : portfolio.past_month_difference) || 0 }) })] }), _jsxs(BadgeColumn, { children: [_jsx(WithSkeleton, { width: 69, height: 15, isLoading: isLoading, children: _jsx(BadgeLabel, { children: `${(portfolio === null || portfolio === void 0 ? void 0 : portfolio.whole_period_days_count) || 0}${t('portfolio.days_short')}.` }) }), _jsx(WithSkeleton, { width: 112, height: 32, isLoading: isLoading, children: _jsx(IncomeBadge, { badgeVariant: 'tinted', percentageIncome: (portfolio === null || portfolio === void 0 ? void 0 : portfolio.overall_difference) || 0 }) })] }), _jsxs(BadgeColumn, { children: [_jsx(WithSkeleton, { width: 69, height: 15, isLoading: isLoading, children: _jsx(BadgeLabel, { children: t('portfolio.mdd.short') }) }), _jsx(WithSkeleton, { width: 112, height: 32, isLoading: isLoading, children: _jsx(MddBadge, { mdd: ((_a = portfolio === null || portfolio === void 0 ? void 0 : portfolio.maxDrawdown) === null || _a === void 0 ? void 0 : _a.yearly) || 0, badgeVariant: 'tinted', badgeEndAdornment: (_jsx(AlertTooltip, { title: 'mdd', iconColor: 'currentColor', placement: 'top', arrow: true })) }) })] }), _jsxs(BadgeColumn, { children: [_jsx(WithSkeleton, { width: 69, height: 15, isLoading: isLoading, children: _jsx(BadgeLabel, { children: t('portfolio.avg_monthly_return') }) }), _jsx(WithSkeleton, { width: 150, height: 32, isLoading: isLoading, children: _jsxs(MonthlyReturnRow, { children: [_jsx(IncomeBadge, { badgeVariant: 'tinted', percentageIncome: userSubscribed ?
                                        ((_b = portfolio === null || portfolio === void 0 ? void 0 : portfolio.user_subscription_summary) === null || _b === void 0 ? void 0 : _b.past_month_difference) || 0 :
                                        (portfolio === null || portfolio === void 0 ? void 0 : portfolio.followers.average_followers_monthly_return) || 0 }), _jsx(MonthlyReturnPositions, { first: {
                                        position: 1,
                                        returnValue: ((_c = portfolio === null || portfolio === void 0 ? void 0 : portfolio.followers) === null || _c === void 0 ? void 0 : _c.average_followers_monthly_return_first) || 0,
                                    }, last: (((_d = portfolio === null || portfolio === void 0 ? void 0 : portfolio.followers) === null || _d === void 0 ? void 0 : _d.count) || 0) > 1 ? {
                                        position: (portfolio === null || portfolio === void 0 ? void 0 : portfolio.followers.count) || 0,
                                        returnValue: (portfolio === null || portfolio === void 0 ? void 0 : portfolio.followers.average_followers_monthly_return_last) || 0,
                                    } : undefined })] }) })] }), _jsx(PortfolioFollowersPreview, { portfolio: portfolio, isLoading: isLoading })] }));
};
export default ListContent;
